<template>
  <div class="box-started">
    <div id="page_news" class="container">
      <div class="columns mt-5">
        <!-- <b-loading v-model="loading" :is-full-page="false"> </b-loading> -->
        <div class="column is-two-thirds">
          <div style="position: relative">
            <b-loading v-model="loading" :is-full-page="false"> </b-loading>
            <div class="group-word" v-if="total > 0">
              <h1 class="key-word">
                คำที่คุณค้นหา
                <span style="color: #0f4c82">{{ dataSearch }}</span>
              </h1>
              <h1 class="key-word">
                ผลลัพธ์การค้นหา <span style="color: #0f4c82">{{ total }}</span>
              </h1>
            </div>
            <div class="columns set-flex">
              <div
                class="column is-half"
                v-for="(data_news, index) in blogSearch"
                :key="index"
              >
                <CardNews :news="data_news" routeName="search" />
              </div>
              <div class="column is-full" v-if="total > 10">
                <b-pagination
                  :total="total"
                  :current="current"
                  :per-page="perPage"
                  icon-prev="chevron-left"
                  icon-next="chevron-right"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  :simple="true"
                  @change="changePage"
                >
                </b-pagination>
              </div>
            </div>
          </div>
          <div style="position: relative">
            <b-loading v-model="loading" :is-full-page="false"> </b-loading>
            <div class="group-word" v-if="total == 0" style="padding-top: 25px">
              <!-- <h1 class="text-center oops">Oops!</h1> -->
              <h1 class="key-word">
                คำที่คุณค้นหา
                <span style="color: #0f4c82">{{ $route.params.id }}</span>
              </h1>
              <br />
              <h1 class="text-center key-word">
                ไม่มีผลลัพธ์สำหรับการค้นหาของคุณ
              </h1>
            </div>
          </div>
        </div>
        <!-- <div class="column is-two-thirds" >
         
        </div> -->
        <div class="column pt-0" style="padding-bottom: 80px">
          <div class="column is-full">
            <div
              class="box-social"
              v-for="(data_social, index) in socialList"
              :key="index"
            >
              <Social :social="data_social" />
            </div>
          </div>
          <HomePopular :blogPopular="blogPopular" v-if="loading == false" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.group-word {
  .key-word {
    font-size: 24px;
    span {
      font-weight: 500;
    }
  }
  margin-bottom: 30px;
}
.oops {
  font-family: Kanit, sans-serif;
  font-size: 110px;
  margin: 0;
  font-weight: 900;
  background: url(../assets/bgText.png) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: 50%;
}
</style>
<script>
import CardNews from "@/components/Card";
import Social from "@/components/Social";
import HomePopular from "@/components/HomePopular";
import { BLOG } from "@/service/axios.js";
export default {
  data() {
    return {
      total: 0,
      current: 1,
      perPage: 10,
      blogPopular: [],
      blogSearch: [],
      getDataSearch: [],
      dataSearch: "",
      loading: true,
    };
  },
  components: {
    CardNews,
    Social,
    HomePopular,
  },
  computed: {
    nameParamsId() {
      return this.$route.params.id;
    },
    socialList() {
      return this.$store.state.socialList;
    },
  },
  watch: {
    nameParamsId() {
      if (this.$route.params.id != undefined) {
        this.searchBlog();
      }
    },
  },
  mounted() {
    this.getBlogPopular();
    this.setParams();
  },
  methods: {
    setParams() {
      console.log("d");
      if (this.$route.params != null) {
        if (typeof this.$route.params.id != "undefined") {
          this.dataSearch = this.$route.params.id;
          if (this.dataSearch != "") {
            this.searchBlog();
          }
        }
      }
    },
    changePage(page) {
      this.current = page;
      this.blogSearch = this.getDataSearch.slice(
        (this.current - 1) * 10,
        this.current * 10
      );
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    searchBlog() {
      this.dataSearch = this.$route.params.id;
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=all&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.getDataSearch = res.data.posts;
            this.blogSearch = this.getDataSearch.filter(
              (filter) =>
                filter.title
                  .toLowerCase()
                  .indexOf(this.dataSearch.toLowerCase()) > -1 ||
                filter.html
                  .toLowerCase()
                  .indexOf(this.dataSearch.toLowerCase()) > -1
            );
            this.total = this.blogSearch.length;
            this.loading = false;
            console.log(this.blogSearch);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    getBlogPopular() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=5b&filter=featured:true&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogPopular = res.data.posts;
            this.loading = false;
            console.log("blogPopular", this.blogPopular);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
};
</script>